import { PromoCodePageElementType } from '@/controllers/graphql/generated';

export enum PromoElementType {
  BANNER = 'promoBannerProps',
  SECTION = 'promoSectionProps',
}

export enum PromoSectionType {
  BANNER = 'banner',
  CARD = 'card',
}

export interface PromoTimeSettings {
  startedAt: string;
  expiredAt?: string;
  repeatDaysSteps?: number[];
}

interface BasePromoLandingElement {
  id: number;
  slug: string;
  titleText: string;
  buttonText: string;
  timeSettings: PromoTimeSettings;
}

export interface PromoLandingBanner extends BasePromoLandingElement {
  type: PromoElementType.BANNER;
  descriptionText?: null;
}

export interface PromoLandingSection extends BasePromoLandingElement {
  type: PromoElementType.SECTION;
  descriptionText: string;
}

export interface PromoLandingElements {
  [PromoElementType.BANNER]: PromoLandingBanner | null;
  [PromoElementType.SECTION]: PromoLandingSection | null;
}

export const PromoElementTypeMapper: Record<
  PromoCodePageElementType,
  PromoElementType
> = {
  [PromoCodePageElementType.Banner]: PromoElementType.BANNER,
  [PromoCodePageElementType.PageSection]: PromoElementType.SECTION,
};

export interface NormalizedLandingPromo {
  id: number;
  slug: string;
  pagePattern: string | null;
  pageElements: PromoCodePageElementType[];
  // Time
  startedAt: string;
  expiredAt: string | null;
  timerConfig: {
    repeatDaysSteps: number[] | null;
    hideDays: number[] | null;
  };
  // Texts
  bannerTitleText: string;
  bannerButtonText: string;
  pageSectionTitleText: string;
  pageSectionDescriptionText: string;
  pageSectionButtonText: string;
}
