import React from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { withLayout } from '@/controllers/layout/layout.hocs/withLayout';
import { compose } from '@/lib/compose';
import { withNamespaces } from '@/controllers/i18n/i18n.hocs/withNamespaces';
import { HomePageModule } from '@/components/homepageLanding/HomePageModule';
import { EmptyLandingLayout } from '@/components/layout/scss/EmptyLandingLayout';

const IndexPage = () => (
  <HomePageModule />
);

export default compose(
  withLayout(EmptyLandingLayout),
  withNamespaces([
    I18N_CODES.common,
    I18N_CODES.home,
    I18N_CODES.course,
    I18N_CODES.seo,
    I18N_CODES.price,
    I18N_CODES.aboutUs,
    I18N_CODES.team, // otherCourses block
    I18N_CODES.companies,
    I18N_CODES.quote,
    I18N_CODES.reviews,
    I18N_CODES.cta,
    I18N_CODES.employmentSteps, // employmentSteps block
    I18N_CODES.countrySelector, // country selector modal
    I18N_CODES.faq, // faq block
    I18N_CODES.homeworkReviews, // homework reviews plugin
  ]),
)(IndexPage);
