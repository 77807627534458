import { LandingPromoInfoFragment } from '@/controllers/promoCode/generated/landingPromoInfo.fragment.generated';
import {
  PromoLandingElements,
  NormalizedLandingPromo,
  PromoElementType,
  PromoElementTypeMapper,
  PromoTimeSettings,
} from '@/controllers/promoCode/promoCode.typedefs';
import { MinimumArray } from '@/lib/helpers/utility-types';
import { checkArrayLength } from '@/lib/helpers/checkArrayLength';
import { DAY } from '@/constants';
import { EMPTY_PROMO_LANDING_ELEMENTS } from '@/controllers/promoCode/promoCode.constant';
import { ConsultationCourses } from '@/components/courseLanding/CourseLanding.constants';
import { ROUTES } from '@/controllers/router/router.contants';
import { createQueryString } from '@/controllers/router/router.utils/createQueryString';

export class PromoCodeHelpers {
  private static normalizeSinglePromo(
    promo: LandingPromoInfoFragment,
  ): NormalizedLandingPromo {
    const {
      id,
      slug,
      pagePattern = null,
      pageElements,
      startedAt,
      expiredAt = null,
      timerConfig,
      bannerTitleText,
      bannerButtonText,
      pageSectionTitleText,
      pageSectionDescriptionText,
      pageSectionButtonText,
    } = promo;

    return {
      id,
      slug,
      pagePattern,
      pageElements: pageElements.map(({ elementType }) => elementType),

      startedAt,
      expiredAt,
      timerConfig: {
        repeatDaysSteps: timerConfig?.repeatDaysSteps || null,
        hideDays: timerConfig?.hideDays || null,
      },

      bannerTitleText,
      bannerButtonText,
      pageSectionTitleText,
      pageSectionDescriptionText,
      pageSectionButtonText,
    };
  }

  static normalizeActiveLandingPromosResponse(
    promos: LandingPromoInfoFragment[],
  ): NormalizedLandingPromo[] {
    return promos.map((promo) => this.normalizeSinglePromo(promo));
  }

  private static preparePromoLandingElements(
    promo: NormalizedLandingPromo,
  ): PromoLandingElements {
    const {
      id,
      slug,
      bannerTitleText,
      bannerButtonText,
      pageSectionTitleText,
      pageSectionDescriptionText,
      pageSectionButtonText,
      startedAt,
      expiredAt,
      timerConfig,
    } = promo;

    return promo.pageElements.reduce<PromoLandingElements>(
      (acc, element) => {
        const key = PromoElementTypeMapper[element];

        return {
          ...acc,
          [key]: {
            id,
            slug,
            titleText:
              key === PromoElementType.BANNER
                ? bannerTitleText
                : pageSectionTitleText,
            descriptionText:
              key === PromoElementType.BANNER
                ? null
                : pageSectionDescriptionText,
            buttonText:
              key === PromoElementType.BANNER
                ? bannerButtonText
                : pageSectionButtonText,
            timeSettings: {
              startedAt,
              expiredAt: expiredAt ?? undefined,
              repeatDaysSteps: timerConfig?.repeatDaysSteps ?? undefined,
            },
          },
        };
      },
      {
        [PromoElementType.BANNER]: null,
        [PromoElementType.SECTION]: null,
      },
    );
  }

  private static filterPromosByPathname(
    promos: NormalizedLandingPromo[],
    pathname: string,
  ): NormalizedLandingPromo[] {
    return promos.filter((promo) => {
      if (!promo.pagePattern) {
        return true;
      }

      const regex = new RegExp(promo.pagePattern);

      return regex.test(pathname);
    });
  }

  private static findLatestActualPromo(
    promos: NormalizedLandingPromo[],
  ): NormalizedLandingPromo | null {
    const now = new Date().getTime();
    const actualPromo = promos.find(
      ({ startedAt }) => Number(startedAt) <= now,
    );

    return actualPromo || null;
  }

  private static filterPromosByHideDays(
    promos: NormalizedLandingPromo[],
  ): NormalizedLandingPromo[] {
    const todayDayNumber = new Date().getDay();

    return promos.filter((promo) => {
      const hideDays = promo.timerConfig?.hideDays || [];

      return !hideDays.includes(todayDayNumber);
    });
  }

  static getPromoLandingElements(
    promos: NormalizedLandingPromo[],
    pathname: string,
  ): PromoLandingElements {
    const filteredPromos = this.filterPromosByPathname(promos, pathname);
    const promosNotOnHideDays = this.filterPromosByHideDays(filteredPromos);
    const latestPromo = this.findLatestActualPromo(promosNotOnHideDays);

    if (!latestPromo) {
      return EMPTY_PROMO_LANDING_ELEMENTS;
    }

    return this.preparePromoLandingElements(latestPromo);
  }

  static getTimerExpiredAtTime(options: PromoTimeSettings): number {
    const {
      startedAt,
      expiredAt,
      repeatDaysSteps = [],
    } = options;

    if (expiredAt && Number(expiredAt) <= Date.now()) {
      return 0;
    }

    if (expiredAt && !repeatDaysSteps.length) {
      return Number(expiredAt);
    }

    const repeatDaysStepsConfig: MinimumArray<1, number> = (
      checkArrayLength(repeatDaysSteps, 1)
        ? repeatDaysSteps
        : [3]
    );

    const nextExpirationTime = this.getNextExpirationTime(
      Number(startedAt),
      repeatDaysStepsConfig,
    );

    if (expiredAt && nextExpirationTime > Number(expiredAt)) {
      return Number(expiredAt);
    }

    return nextExpirationTime;
  }

  static getNextExpirationTime(
    startedAtTime: number,
    repeatDaysSteps: MinimumArray<1, number>,
  ) {
    // Calculate the total elapsed time in days since the start
    const elapsedTimeInDays = Math.floor((Date.now() - startedAtTime) / DAY);

    // Sum the intervals and determine the next interval based on the start time
    let totalDays = 0;
    let intervalIndex = 0;

    while (totalDays <= elapsedTimeInDays) {
      const currentInterval = (
        repeatDaysSteps[intervalIndex]
        || repeatDaysSteps[0]
      );

      totalDays += currentInterval;

      intervalIndex = (intervalIndex + 1) % repeatDaysSteps.length;
    }

    // Calculate the next expiration time based on the startedAtTime
    return startedAtTime + totalDays * DAY;
  }

  static getConsultationLink(options: {
    promoCodeSlug: string;
    eventSource: string;
    courseSlug?: string;
    professionSlug?: string;
  }): string {
    const {
      promoCodeSlug,
      eventSource,
      courseSlug = ConsultationCourses.GeneralConsultation,
      professionSlug,
    } = options;
    const queryParams = createQueryString({
      source: eventSource,
      promo_code: promoCodeSlug,
      course_slug: courseSlug,
      profession_slug: professionSlug,
    });

    return `${ROUTES.consultation.index}${queryParams}`;
  }

  static getPromoEventSource(options: {
    promoCodeSlug: string;
    promoSection: string;
  }): string {
    const {
      promoCodeSlug,
      promoSection,
    } = options;

    return `${promoCodeSlug}_${promoSection}`;
  }
}
