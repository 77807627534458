import { useCallback, useEffect, useRef } from 'react';
import {
  ExitPopupEventType,
  useExitPopupEventEmitter,
} from '@/components/common/ExitPopup/hooks/useExitPopupEventEmitter';
import {
  initExitPopupOpenedStorage,
  DELAY,
} from '@/components/common/ExitPopup/ExitPopup.constants';
import { debounce } from '@/lib/helpers/debounce';
import { useRouterPathWithoutParams } from '@/components/common/ExitPopup/hooks/useRouterPathWithoutParams.hook';
import { useGrowthBookExperiment } from '@/components/services/GrowthBook/GrowthBook.hooks/useGrowthBookExperiment';
import { GBExperiments } from '@/components/services/GrowthBook/GrowthBook.constants';

interface Props {
  isOpen: boolean;
  open: () => void;
}

export const usePopupAutoOpen = (props: Props) => {
  const {
    isOpen,
    open,
  } = props;
  const pathname = useRouterPathWithoutParams();
  const exitPopupOpenedStorage = initExitPopupOpenedStorage(pathname);
  const exitPopupEventEmitter = useExitPopupEventEmitter();

  const handlePopupOpen = useCallback(() => {
    const shouldNotOpen = isOpen || exitPopupOpenedStorage.readFromStorage();

    if (shouldNotOpen) {
      return;
    }

    open();
    exitPopupOpenedStorage.writeToStorage(true);
  }, [open, isOpen, exitPopupOpenedStorage]);

  // EXPERIMENT
  const {
    isVariant1: isShorterTriggerVariant,
  } = useGrowthBookExperiment(
    GBExperiments.shorterPopupTriggerUa.feature,
  );

  const INACTIVE_DELAY = isShorterTriggerVariant
    ? DELAY.INACTIVE / 2
    : DELAY.INACTIVE;
  const FIRST_TRIGGER_DELAY = isShorterTriggerVariant
    ? DELAY.POPUP / 2
    : DELAY.POPUP;

  // Case #1: User cursor leaves page viewport
  useEffect(() => {
    const EVENT_NAME = 'mouseleave';

    let timeoutId: ReturnType<typeof setTimeout>;

    const restartPopupTimeout = () => {
      clearTimeout(timeoutId);
      document.removeEventListener(EVENT_NAME, handlePopupOpen);

      timeoutId = setTimeout(() => {
        document.addEventListener(EVENT_NAME, handlePopupOpen);
      }, FIRST_TRIGGER_DELAY);
    };

    restartPopupTimeout();

    exitPopupEventEmitter.listen(
      ExitPopupEventType.ResetExitPopupTimeout,
      restartPopupTimeout,
    );

    return () => {
      document.removeEventListener(EVENT_NAME, handlePopupOpen);

      exitPopupEventEmitter.unsubscribe(
        ExitPopupEventType.ResetExitPopupTimeout,
        restartPopupTimeout,
      );
      clearTimeout(timeoutId);
    };
  }, [exitPopupEventEmitter, open, handlePopupOpen, FIRST_TRIGGER_DELAY]);

  // Case #2: User is inactive for a certain period of time
  useEffect(() => {
    const EVENTS = ['click', 'scroll', 'mousemove', 'keypress'];

    let timeout = setTimeout(handlePopupOpen, INACTIVE_DELAY);

    const clear = debounce(() => {
      clearTimeout(timeout);
      timeout = setTimeout(handlePopupOpen, INACTIVE_DELAY);
    }, 100);

    EVENTS.forEach((event) => {
      document.addEventListener(event, clear);
    });

    exitPopupEventEmitter.listen(
      ExitPopupEventType.ResetExitPopupTimeout,
      clear,
    );

    return () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, clear);
      });
      exitPopupEventEmitter.unsubscribe(
        ExitPopupEventType.ResetExitPopupTimeout,
        clear,
      );
      clearTimeout(timeout);
    };
  }, [exitPopupEventEmitter, open, handlePopupOpen, INACTIVE_DELAY]);

  // Case #3: User scrolls % of the page height
  const scrollPositionRef = useRef(0);
  const pageHeightRef = useRef(0);

  useEffect(() => {
    const pageHeightTriggerPoint = 0.4;

    const handleScroll = debounce(() => {
      const triggerPoint = pageHeightRef.current * pageHeightTriggerPoint;

      scrollPositionRef.current = window.scrollY;

      if (scrollPositionRef.current >= triggerPoint) {
        handlePopupOpen();
        window.removeEventListener('scroll', handleScroll);
      }
    }, 100);

    pageHeightRef.current = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
    );

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handlePopupOpen, exitPopupOpenedStorage]);
};
