import React, { memo } from 'react';
import { PromoBanner } from '@/components/landing/Promotions/components/PromoBanner';

import { IconEmojiWrappedGift } from '@/components/ui/IconEmojies/IconEmojiWrappedGift';
import { ELLIPSE_COLOR_PINK, ELLIPSE_COLOR_PURPLE } from '@/components/landing/Promotions/promotions.constants';
import { PromoCodeHelpers } from '@/controllers/promoCode/promoCode.helpers';
import { PromoLandingBanner, PromoSectionType } from '@/controllers/promoCode/promoCode.typedefs';

interface Props extends PromoLandingBanner {
  courseSlug?: string;
  professionSlug?: string;
  shouldShowRedesigned?: boolean;
}

export const UniversalPromoBanner = memo<Props>((props) => {
  const {
    courseSlug,
    shouldShowRedesigned,
    slug: promoCodeSlug,
    titleText,
    buttonText,
    timeSettings,
    professionSlug,
  } = props;

  const eventSource = PromoCodeHelpers.getPromoEventSource({
    promoCodeSlug,
    promoSection: PromoSectionType.BANNER,
  });

  const promoCodeExpireAt = PromoCodeHelpers.getTimerExpiredAtTime(
    timeSettings,
  );

  if (!promoCodeExpireAt) {
    return null;
  }

  return (
    <PromoBanner
      promoName={promoCodeSlug}
      source={eventSource}
      TitleIcon={IconEmojiWrappedGift}
      tagText={titleText}
      timerEndAt={promoCodeExpireAt}
      buttonText={buttonText}
      ellipseColorLeft={ELLIPSE_COLOR_PURPLE}
      ellipseColorRight={ELLIPSE_COLOR_PINK}
      href={PromoCodeHelpers.getConsultationLink({
        promoCodeSlug,
        eventSource,
        courseSlug,
        professionSlug,
      })}
      shouldShowRedesigned={shouldShowRedesigned}
    />
  );
});
