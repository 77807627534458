import React, { FC } from 'react';
import { StudyingFormatsSection } from '@/components/homepageLanding/StudyingFormatsSection';
import {
  ProfessionsListSection,
} from '@/components/homepageLanding/ProfessionsListSection';
import { PageExitPopup } from '@/components/landing/PageExitPopup';
import {
  HomePageCoverSection,
} from '@/components/homepageLanding/HomePageCoverSection';
import {
  OtherLearningTracksBlock,
} from '@/components/courseLanding/OtherLearningTracksBlock';
import { CourseType } from '@/controllers/graphql/generated';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  COURSE_BLOCKS,
} from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { CompaniesBlock } from '@/components/landing/CompaniesBlock';
import { ReviewsBlock } from '@/components/landing/ReviewsBlock';
import { Quote } from '@/components/landing/Quote';
import {
  usePublishingReviews,
} from '@/controllers/publishingsReviews/publishingsReviews.hooks';
import {
  PublishingReviewsSection,
} from '@/components/homepageLanding/PublishingReviewsSection';
import {
  HOMEPAGE_SECTION_IDS,
} from '@/components/homepageLanding/HomePageContent/HomePageContent.constants';
import { section } from '@/components/ui/section';
import { FAQBlock } from '@/components/landing/FAQBlock/FAQBlock';
import { useFAQItems } from '@/controllers/faq/faq.hooks/useFAQItems';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  useAllCompanies,
} from '@/controllers/companies/companies.hooks/useAllCompanies';
import { useReviews } from '@/controllers/reviews/reviews.hooks/useReviews';
import { Features } from '@/controllers/features/features.constants';
import { SubDomains } from '@/constants/subdomains';
import {
  VideoReviewsSection,
} from '@/components/reviews-landing/VideoReviewsSection';
import {
  useCourseVideoReviews,
} from '@/controllers/reviews/reviews.hooks/useCourseReviews';
import {
  useFAQPagesGeneric,
} from '@/controllers/faq/faq.hooks/useFAQPagesGeneric';
import {
  useShouldShowOnlyPrepaidCourses,
} from '@/components/hooks/useShouldShowOnlyPrepaidCourses.hook';
import { useShouldShowProfessions } from '@/components/hooks/useShouldShowProfessions.hook';
import { cn } from '@/lib';
import { EmploymentGuaranteeSection } from '@/components/courseLanding/EmploymentGuarantee/EmploymentGuaranteeSection';
import styles from './HomePageContent.module.scss';
import { CardMode } from '../ProfessionsListSection/components/ProfessionCard/ProfessionCard.typedefs';

export const HomePageContent: FC = () => {
  const { t } = useTranslation([
    I18N_CODES.home,
    I18N_CODES.team,
    I18N_CODES.reviews,
    I18N_CODES.companies,
    I18N_CODES.faq,
    I18N_CODES.aboutUs,
    I18N_CODES.course,
  ]);

  const { subDomain } = useSubDomainContext();
  const features = useFeatures();

  const companies = useAllCompanies();
  const [reviews] = useReviews();
  const [publishingReviews] = usePublishingReviews(subDomain);

  const faqItemsPages = useFAQPagesGeneric();
  const [faqItems] = useFAQItems({
    pages: faqItemsPages,
  });

  const shouldShowOnlyPrepaidCourses = useShouldShowOnlyPrepaidCourses();

  const mainCourseType = (
    shouldShowOnlyPrepaidCourses
      ? CourseType.Prepaid
      : CourseType.Postpaid
  );

  const videoReviewsPLEnabled = features.isEnabled(Features.VideoReviewsPL);

  const shouldGetVideoReviews = (
    videoReviewsPLEnabled
    && subDomain === SubDomains.pl
  );

  const videoReviews = useCourseVideoReviews({
    isBasedOnDomain: true,
    skip: !shouldGetVideoReviews,
  });

  const shouldShowVideoReviews = videoReviews.length > 0;

  const shouldShowProfessions = useShouldShowProfessions();

  return (
    <>
      <div className={styles.overflowContainer}>
        <div className="mb-168 small-mb-120">
          <HomePageCoverSection
            title={t(`${I18N_CODES.home}:cover_title`)}
          />
        </div>

        {shouldShowOnlyPrepaidCourses
          ? (
            <div className="mb-168 small-mb-120">
              <ProfessionsListSection
                id={COURSE_BLOCKS.allCourses}
                cardMode={CardMode.SINGLE}
                courseTypes={[CourseType.Prepaid]}
              />
            </div>
          )
          : (
            <>
              <div className="mb-168 small-mb-120">
                <StudyingFormatsSection />
              </div>

              <div className="mb-168 small-mb-120">
                <ProfessionsListSection
                  id={COURSE_BLOCKS.allCourses}
                  cardMode={shouldShowProfessions
                    ? CardMode.SINGLE
                    : CardMode.DOUBLE}
                  courseTypes={[CourseType.Prepaid, CourseType.Postpaid]}
                />
              </div>
            </>
          )}
      </div>

      {shouldShowVideoReviews && (
        <div className={styles.ellipsesLimitingWrapper}>
          <div className='mb-168 small-mb-120 grid-container'>
            <VideoReviewsSection
              reviews={videoReviews}
              shouldShowShowAllReviewsButton={false}
            />
          </div>
        </div>
      )}

      <div className={cn('mb-168 small-mb-120', styles.ellipsesLimitingWrapper)}>
        <EmploymentGuaranteeSection />
      </div>

      {(companies.length > 0) && (
        <div className="mb-168 small-mb-120 grid-container">
          <div className="grid-x align-center">
            <div className="xlarge-8">
              <CompaniesBlock
                title={t(`${I18N_CODES.companies}:employed_in_title`)}
                moreItemsText={t(`${I18N_CODES.companies}:employed_more_companies_text`)}
                id={COURSE_BLOCKS.companies}
                companies={companies}
              />
            </div>
          </div>
        </div>
      )}

      <div className="mb-200 small-mb-120">
        <Quote />
      </div>

      {publishingReviews.length > 0 && (
        <div className="mb-168 small-mb-120">
          <PublishingReviewsSection
            id={HOMEPAGE_SECTION_IDS.publishingReviews}
            publishingReviews={publishingReviews}
          />
        </div>
      )}

      {reviews.length > 0 && (
        <div className="mb-168 small-mb-120">
          <ReviewsBlock
            id={COURSE_BLOCKS.reviews}
            reviews={reviews}
            title={t(`${I18N_CODES.reviews}:homepage_reviews_title`)}
          />
        </div>
      )}

      <div
        id={HOMEPAGE_SECTION_IDS.faq}
        className={section.scrollSection}
      >
        {faqItems.length > 0 && (
          <div className="mb-200 small-mb-40">
            <FAQBlock
              items={faqItems}
              title={t(`${I18N_CODES.faq}:faq_title`)}
            />
          </div>
        )}
      </div>

      <div className="mb-48 small-mb-40">
        <OtherLearningTracksBlock
          id={COURSE_BLOCKS.otherCourses}
          title={t(`${I18N_CODES.team}:our_courses`)}
          types={[mainCourseType]}
        />
      </div>

      {features.isEnabled(features.HomePageExitPopup) && (
        <PageExitPopup source='home_page_exit_popup' />
      )}
    </>
  );
};
