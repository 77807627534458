import { useMemo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import {
  useActiveLandingPromosByDomainQuery,
} from '@/controllers/promoCode/generated/activeLandingPromosByDomain.query.generated';
import { PromoCodeHelpers } from '@/controllers/promoCode/promoCode.helpers';
import { NormalizedLandingPromo } from '@/controllers/promoCode/promoCode.typedefs';

interface Output {
  landingPromos: NormalizedLandingPromo[];
  loading: boolean;
}

export const useActiveLandingPromos = (): Output => {
  const { language, subDomain } = useSubDomainContext();

  const { data, loading } = useActiveLandingPromosByDomainQuery({
    variables: {
      domainSlug: subDomain,
      lang: language,
    },
    ssr: false,
  });

  const landingPromos = useMemo(
    () => PromoCodeHelpers.normalizeActiveLandingPromosResponse(
      data?.activeLandingPromosByDomain || [],
    ),
    [data],
  );

  return {
    landingPromos,
    loading,
  };
};
